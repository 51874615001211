

























































































































































@import '@design';

.home-merch-section {
  .img-card {
    height: 100%;
    min-height: 33vh;
    border-radius: 0;
  }
}

.blue-oak-web-menu {
  &.grid {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      grid-template-areas: '. left center .' '. right . .' !important;
      grid-template-columns: 1fr 4fr 4fr 1fr !important;
    }

    @media (max-width: 900px) {
      grid-template-areas: 'left center' 'right .' !important;
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 650px) {
      grid-template-areas: 'left' 'center' 'right' !important;
      grid-template-columns: 1fr !important;
    }
  }
}
.blue-oak-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }
  @media (max-width: 650px) {
    padding: 3px;
  }
}
